import { isShift } from '~/utils/isShift'

export type Browser = 'SHIFT' | 'ONELAUNCH'

function getCurrentBrowser(): Browser {
  if (isShift()) {
    return 'SHIFT'
  }

  return 'ONELAUNCH'
}

export default function useBrowser(): Ref<Browser | undefined> {
  return useState<Browser | undefined>('browser', getCurrentBrowser)
}
